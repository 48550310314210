<template>
  <PageContainer>
    <div class="layout">
      <div style="width: 100%; display: flex; align-items: center">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px; padding-left: 20px"
          size="mini"
        >
          <el-form-item label="所属区域:">
            <el-cascader
              ref="myCascader"
              v-model="selectedOptions"
              clearable
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="企业名称:" prop="compName">
            <el-input v-model="searchModel.compName"></el-input>
          </el-form-item>
          <el-form-item label="企业类型:">
            <DataSelect v-model="searchModel.compType" dictCode="norm_comp_type"></DataSelect>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitSearch"
              :loading="loading"
            >{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-close"
              v-if="loading"
              @click="loading = false"
            >停止</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="compBaseInfo"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :edit-config="{trigger: 'click', mode: 'cell'}"
          :seq-config="{
            startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
          }"
          :data="tableData"
        >
          <template #buttons>
            <!--            <el-button
              v-auth="'信息管理_企业备案_基本信息_新增'"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="
                dialogEdit = true;
                addRow();
              "
            >新增企业
            </el-button>-->
          </template>
          <vxe-table-column type="seq" title="序号" width="60" />
          <!--   <vxe-table-column field="projNum" title="编号" show-overflow="title" min-width="135" show-header-overflow="title"/>-->
          <vxe-table-column
            field="compOrder"
            title="企业排序"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="compName"
            title="企业名称"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="compTypeName"
            title="企业类型"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <!--  <vxe-table-column field="compId" title="企业编号" show-overflow="title" min-width="60" show-header-overflow="title"/>-->
          <vxe-table-column
            field="legalName"
            title="法定代表人"
            show-overflow="title"
            min-width="120"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="compPId"
            title="所属企业"
            show-overflow="title"
            min-width="120"
            show-header-overflow="title"
            :formatter="
              ({ cellValue, row, column }) => {
                return cellValue == 0 ? '' : compMap[cellValue];
              }
            "
          />
          <vxe-table-column
            field="cityName"
            title="所属区域"
            show-overflow="title"
            min-width="120"
            show-header-overflow="title"
            :formatter="
              ({ celllValue, row, column }) => {
                if (row.cityName != '直辖区')
                  return row.provinName + row.cityName + row.countrName;
                else return row.provinName + row.countrName;
              }
            "
          />
          <vxe-table-column
            field="address"
            title="企业地址"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="authenticStatus"
            title="授权状态"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
            :formatter="
              ({ cellValue, row, column }) => {
                return cellValue ? authStatuOptions[cellValue] : '';
              }
            "
          />
          <!--          <vxe-table-column title="操作" width="280">
            <template v-slot="{ row }">
              <el-button type="primary"  v-auth="'信息管理_企业备案_基本信息_二维码'" @click="handlerQrcode(row)" title="二维码">二维码</el-button>
              <el-button
                v-auth="'mortconc_compcs_boundCawa'"
                @click="handlerBind(row)"
                title="绑定大平台企业"
                type="primary"
              >绑定</el-button>
              <el-button
                type="primary"
                @click="editRow(row)"
                v-auth="'信息管理_企业备案_基本信息_修改'"
              >修改</el-button>
              <el-button
                type="danger"
                @click="deleteRow(row)"
                v-auth="'信息管理_企业备案_基本信息_删除'"
              >删除</el-button>
              <el-button
                type="success"
                @click="detailRow(row)"
                v-auth="'信息管理_企业备案_基本信息_详情'"
              >详情</el-button>
            </template>
          </vxe-table-column>-->
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="[
          'Sizes',
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Total',
        ]"
        @page-change="handlePageChange"
      />
      <el-dialog
        :title="dialogEditTitle"
        :visible.sync="dialogEdit"
        :v-if="dialogEdit"
        :append-to-body="true"
        width="750px"
        v-dialogDrag
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :inline="true"
          ref="compForm"
          :model="compEditModel"
          size="mini"
          label-position="right"
          :rules="rules"
          label-width="120px"
          style="margin-top: 20px"
        >
          <el-form-item label="企业名称:" prop="compName">
            <el-input v-model="compEditModel.compName" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="企业类型:">
            <DataSelect v-model="compEditModel.compType" dictCode="norm_comp_type"></DataSelect>
          </el-form-item>
          <el-form-item label="企业简称:">
            <el-input v-model="compEditModel.compName0" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="企业编号:">
            <el-input v-model="compEditModel.compNumber" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="所属区域:">
            <el-cascader
              ref="editCascader"
              v-model="regionArr"
              clearable
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              style="width: 180px"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="父级企业:" prop="compPId">
            <el-select
              v-model="compEditModel.compPId"
              placeholder="请选择所属企业"
              filterable
              clearable
              style="width: 200px"
            >

              <template v-for="item in companyList">
                <el-option
                  v-if="item.compId != compEditModel.compId"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                >
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="企业资质:" prop="qualificationLevel">
            <el-input
              v-model="compEditModel.qualificationLevel"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="注册资金(万元):" prop="registeredCapital">
            <el-input
              v-model="compEditModel.registeredCapital"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <!--        <el-form-item label="法定代表人:" prop="legalName">
                  <el-input
                      v-model="compEditModel.legalName"
                      style="width: 180px"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="手机号码:" props="legalPhone">
                  <el-input
                      v-model="compEditModel.legalPhone"
                      style="width: 200px"
                  >
                  </el-input>
                </el-form-item>-->
          <el-form-item label="地址:" props="address">
            <el-input v-model="compEditModel.address" style="width: 510px">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitCompEdit()" size="mini"
          >保存</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        :title="dialogDetailTitle"
        :visible.sync="dialogDetail"
        :v-if="dialogDetail"
        :append-to-body="true"
        width="750px"
        v-dialogDrag
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :inline="true"
          ref="compFormDetail"
          :model="compDetailModel"
          size="mini"
          label-position="right"
          :rules="rules"
          label-width="120px"
          style="margin-top: 20px"
        >
          <el-form-item label="企业名称:" prop="compName">
            <el-input
              v-model="compDetailModel.compName"
              style="width: 180px"
              disabled
            >
            </el-input>
          </el-form-item>
          <el-form-item label="企业类型:">
            <DataSelect v-model="compDetailModel.compType" dictCode="norm_comp_type"></DataSelect>
          </el-form-item>
          <el-form-item label="企业简称:">
            <el-input v-model="compDetailModel.compName0" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="企业编号:">
            <el-input v-model="compDetailModel.compNumber" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="所属区域:">
            <el-cascader
              ref="detailCascader"
              v-model="regionArr"
              clearable
              disabled
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              style="width: 180px"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="父级企业:" prop="compPId">
            <el-select
              v-model="compDetailModel.compPId"
              placeholder="请选择所属企业"
              filterable
              clearable
              disabled
              style="width: 200px"
            >
              <template v-for="item in companyList">
                <el-option
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                  v-if="item.compId != compEditModel.compId"
                >
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="企业资质:">
            <el-input
              v-model="compDetailModel.qualificationLevel"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="注册资金(万元):">
            <el-input
              v-model="compDetailModel.registeredCapital"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <template v-for="(value, key) in persLabels" >
            <template v-if="dialogDetail">
              <el-form-item :key="key" :label="value + ':'" v-if="persCompVos[key]">
                <el-input v-model="persCompVos[key].persName" style="width: 180px">
                </el-input>
              </el-form-item>
              <el-form-item :key="key" label="手机号码:" v-if="persCompVos[key]">
                <el-input v-model="persCompVos[key].phone" style="width: 200px">
                </el-input>
              </el-form-item>
            </template>
          </template>
          <el-form-item label="地址:" props="address">
            <el-input v-model="compDetailModel.address" style="width: 510px">
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
      <qr-code :visible.sync="codeVisible" :content="qrCodeContent" />
      <BindCompDialog :visible.sync="compsVisible" :edit-data.sync="editData" />
    </div>
  </PageContainer>
</template>

<script>
import { gainRegi, getComps } from '@/apis/common'
import { gianSqComplist } from '@/apis/infoManage/compBaseInfo'
import QrCode from '@/components/QrCode'
import BindCompDialog from './BindCompDialog'
import XEUtils from 'xe-utils'

export default {
  components: {
    QrCode,
    BindCompDialog
  },
  name: 'enter-base-info',
  data () {
    return {
      compEditModel: {
        address: '',
        compId: '',
        compName: '',
        compName0: '',
        compType: '',
        compNumber: '',
        compPId: '',
        qualificationLevel: '',
        registeredCapital: ''
      },
      rules: {
        compName: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        qualificationLevel: [
          { min: 1, max: 9, message: '长度在 1 到 9个字符', trigger: 'blur' }
        ],
        registeredCapital: [
          { pattern: '^[1-9]([0-9]*$)', message: '注册资金需为整数', trigger: 'blur' }
        ]
      },
      authStatuOptions: {
        1: '未授权或者已解除授权',
        2: '请求授权',
        3: '已授权'
      },
      dialogEditTitle: '新增企业',
      dialogEdit: false,
      dialogDetailTitle: '企业详情',
      dialogDetail: false,
      compsVisible: false,
      editData: null,
      searchModel: {},
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: [
          'Sizes',
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'FullJump',
          'Total'
        ],
        perfect: true
      },
      lazyTrigger: false,
      regionOptions: [],
      selectedOptions: [],
      regionArr: [],
      companyList: [],
      compMap: {},
      compDetailModel: {},
      regionProps: {
        // multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code]
                }).then((res) => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map((val) => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    }))
                    /*     nodeArr.unshift({
                           value:val.regiProvinId,
                           label:'全市',
                           code:val.provinCode,
                           leaf:true,
                         }) */
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      persLabels: {
        1: '法人',
        10: '总经理',
        15: '安全副总经理',
        20: '技术负责人',
        25: '车队负责人',
        30: '办公室主任',
        35: '网络管理员',
        40: '统计人员'
      },
      persCompVos: {},
      qrCodeContent: '',
      codeVisible: false,
      sexList1: [
        { label: '在生产', value: '1' },
        { label: '有资质未生产', value: '2' }
      ]
    }
  },
  methods: {
    formatSex (value) {
      if (value == '1') {
        return '在生产'
      }
      if (value == '2') {
        return '有资质未生产'
      }
      return ''
    },
    handlerQrcode (row) { // 企业二维码：企业名称、组织机构代码（营业执照）
      this.codeVisible = true
      this.qrCodeContent = `企业名称: ${row.compName}`
    },
    handlerBind (row) {
      this.compsVisible = true
      this.editData = row
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    /*  saveProductStatus (row) {
      setComp({
        compId: row.compId,
        productStatus: row.productStatus
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success('保存成功！')
          this.refreshData()
        } else {
          this.$message.error(res.msg)
        }
      })
    }, */
    refreshData () {
      gianSqComplist({
        regiCountrId: this.selectedOptions[2]
          ? this.selectedOptions[2]
          : undefined,
        regiCityId: this.selectedOptions[1]
          ? this.selectedOptions[1]
          : undefined,
        regiProvinId: this.selectedOptions[0]
          ? this.selectedOptions[0]
          : undefined,
        compType: this.searchModel.compType,
        compName: this.searchModel.compName,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.code == 200) {
            const data = res.data
            this.tableData = data.rows
            this.tablePage.total = data.totalSize
          } else {
            this.tableData = []
            this.tablePage.total = 0
          }
        })
        .catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
    },
    addRow () {
      this.dialogEditTitle = '新增企业'
      this.compEditModel = {
        address: '',
        compId: '',
        compName: '',
        compName0: '',
        compType: '',
        compNumber: '',
        compPId: '',
        qualificationLevel: '',
        registeredCapital: ''
      }
    },
    editRow (row) {
      //  this.modifyDetailRow = row
      this.dialogEditTitle = '修改企业'
      const rowData = {}
      XEUtils.objectEach(this.compEditModel, (item, key) => {
        if (key != 'compPId' && key != 'compType') {
          rowData[key] = row[key] ? row[key].toString() : ''
        } else {
          rowData[key] = row[key] ? row[key] : ''
        }
      })
      this.compEditModel = Object.assign({}, rowData)
      this.regionArr = []
      if (row.regiCountrId) {
        gainRegi({
          isMapJson: false,
          regiLevel: 2,
          regiDto: {} /*  */
        }).then((res) => {
          const targetProvin = this.regionOptions.find(
            (item) => item.value == row.regiProvinId
          )
          let targetCity = res.data.find(
            (item) => item.regiCityId == row.regiCityId
          )
          targetCity = {
            value: targetCity.regiCityId,
            label: targetCity.cityName,
            code: targetCity.cityCode,
            children: targetCity.regiCountrs.map((item) => {
              return {
                value: item.regiCountrId,
                label: item.countrName,
                code: item.countrCode,
                leaf: true
              }
            })
          }
          targetProvin.children.forEach((item, index) => {
            if (item.value == row.regiCityId) {
              this.$set(targetProvin.children, index, targetCity)
            }
          })
        })
      }
      if (row.regiProvinId != '' || row.regiProvinId != '0') {
        this.regionArr.push(row.regiProvinId)
      }
      if (row.regiCityId != '' || row.regiCityId != '0') {
        this.regionArr.push(row.regiCityId)
      }
      if (row.regiCityId != '' || row.regiCityId != '0') {
        this.regionArr.push(row.regiCountrId)
      }

      this.dialogEdit = true
    },
    /*  deleteRow (row) {
      this.$confirm('确定删除该企业?', '删除企业', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        deleteComp({
          ids: [row.compId]
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }, */
    /*  detailRow (row) {
      this.dialogDetail = true
      gainOneComp({
        id: row.compId
      }).then((res) => {
        if (res.code === 200) {
          const perms = {}
          this.compDetailModel = row
          res.data.persCompVos.forEach((item) => {
            perms[item.seniPosit] = {
              phone: item.phone,
              persName: item.persName
            }
          })
          this.persCompVos = perms
        } else {
          this.$message.error(res.msg)
        }
      })
    }, */
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {
      /*  if(this.lazyTrigger){
          console.log('z这里',val)
          const menuIndex = val.length -1
          const cascader = this.$ref.editCascader.menu
          const item = {}
          this.$nextTick(()=>{
            const elem = document.querySelectorAll(".el-cascader-menu__item.is-active")[menuIndex]
            elem.onclick = e =>{
              item.value = val[menuIndex];
              item.label = e.currentTarget.innerText;
              cascader.select(item,menuIndex)
            }
          })
          this.lazyTrigger =false
        } */
    }
    /* onSubmitCompEdit () {
      const pathLabels = this.$refs.editCascader.getCheckedNodes()[0] ? this.$refs.editCascader.getCheckedNodes()[0].pathLabels : []

      this.$refs.compForm.validate((valid) => {
        if (valid) {
          setComp({
            ...this.compEditModel,
            regiCountrId: this.regionArr[2] ? this.regionArr[2] : undefined,
            regiCityId: this.regionArr[1] ? this.regionArr[1] : undefined,
            regiProvinId: this.regionArr[0] ? this.regionArr[0] : undefined,
            countrName: pathLabels[2] ? pathLabels[2] : undefined,
            cityName: pathLabels[1] ? pathLabels[1] : undefined,
            provinName: pathLabels[0] ? pathLabels[0] : undefined
          }).then((res) => {
            if (res.code === 200) {
              if (this.compEditModel.compId != '') {
                this.$message.success('修改成功！')
              } else {
                this.$message.success('添加成功！')
              }
              this.dialogEdit = false
              this.refreshData()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    } */
  },
  mounted () {
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    })
      .then((res) => {
        if (res.code == 200) {
          const regionOptions = res.data.map((val) => ({
            value: val.regiProvinId,
            label: val.provinName,
            code: val.provinCode,
            children: val.regiCitys.map((valCity) => ({
              value: valCity.regiCityId,
              label: valCity.cityName,
              code: valCity.cityCode,
              children: []
            }))
          }))
          this.regionOptions = regionOptions
        }
      })
      .catch((val) => {
        console.log(val)
      })
    getComps({})
      .then((res) => {
        if (res.code == 200) {
          this.companyList = res.data.rows
          const temp = {}
          this.companyList.forEach((item) => {
            temp[item.compId] = item.compName
          })
          this.compMap = temp
        }
        this.refreshData()
      })
      .catch((res) => {
        console.log(res)
      })
  }
}
</script>s

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
</style>
