<template>
  <el-dialog
    v-bind="{title:'企业绑定大平台企业账户信息',width:'600px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form v-loading="loading" ref="form" label-width="120px" :model="submitForm" :rules="rules">
      <el-form-item label="当前企业">
        <el-input class="w-250" v-model="submitForm.compName" readonly></el-input>
      </el-form-item>
      <TransferTree ref="compRef" :data="comps"></TransferTree>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TransferTree from '@/components/TransferTree'
import {
  getPlateComps,
  getCheckedPlateComps,
  bindPlateComps
} from '@/apis/infoManage/compBaseInfo'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    }
  },
  data () {
    return {
      comps: [],
      compIds: [],
      loading: false,
      submitForm: {
        compId: '',
        objs: [],
        compName: ''
      },
      rules: {}
    }
  },
  methods: {
    submit () {
      const submitForm = { ...this.submitForm }
      const comps = this.comps
      const checkedIds = this.$refs.compRef.getKeys()
      submitForm.objs = comps.filter(item => checkedIds.includes(item.companyID))
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          bindPlateComps(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
      this.$refs.compRef.setKeys([])
      this.comps = []
    },

    // 初始化表单
    handlerOpen () {
      const submitForm = this.submitForm
      const editData = this.editData
      if (!editData) return
      Object.keys(submitForm).forEach(key => {
        const value = editData[key]
        if (value) submitForm[key] = value
      })

      const { compName, relationType, compId } = this.editData
      const p1 = getPlateComps({
        name0: compName,
        type0: relationType
      }).then((res) => {
        if (res.code == 200) {
          this.comps = res.data.rows.map(item => {
            item.id = item.companyID
            item.name = item.companyName
            return item
          })
        } else {
          this.comps = []
        }
      })
      let checkedIds = []
      const p2 = getCheckedPlateComps({
        compId
      }).then((res) => {
        checkedIds = (res.data.rows || []).map((item) => item.companyID)
      })
      Promise.all([p1, p2]).then(() => {
        this.$refs.compRef.setKeys(checkedIds)
      })
    }
  },
  components: {
    TransferTree
  }
}
</script>
