import http from '@/utils/http'

// 企业列表
// export const gianComplist = p => http.post('/mortconc/compcs/list', p)

// 企业调整(新增或修改)
export const setComp = p => http.post('/mortconc/compcs/set', p)

// 企业删除
export const deleteComp = p => http.post('/mortconc/compcs/del', p)

// 企业单条
export const gainOneComp = p => http.post('/mortconc/compcs/one', p)

// 获取待选大平台企业
export const getPlateComps = (p = {}) => http.post('/mortconc/compcs/moreTrip', p)

// 获取已选大平台企业
export const getCheckedPlateComps = p => http.post('/mortconc/compcs/listCompCawa', p)

// 企业绑定大平台企业账户信息
export const bindPlateComps = p => http.post('/mortconc/compcs/boundCawa', p)

// 授权企业列表
export function gianSqComplist (p) {
  return http.post('/mortconc/compcs/listCompComp', p)
}
